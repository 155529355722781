/*------------------------------------------
Project Name : Dexim Jobs
Desgined By  : DeximLabs.com
Developed By : DeximLabs.com;
Date        :  January/17;

Table Of Index
------------------
1. Base css
2. Login buttons 
3. Mobile Menu
4. Header Section
5. Animations
6. Navigation Change to Hover
7. Navigatiosns
8. Slogan Area
9. Jobs Filters
10. Main Container
11. Tabs Filters
12. Filters Result
13. Continer Blue Section
14. Jobs Conter
15. Price Tag
16. Pricing Table
17. Postslists
18. Blue Banner
19. Footer
20. Job Admin
21. Sidebar
22. Recommended Jobs
23. Top Jobs Filters
24. Job list style 2
25. Pricing Tabes
26. Page How its Works
27. Job slider
28. Terms and conditions
29. Job slider
30. Login and Register
31. Post A Job
32. Checkout
33. Companies
34. Model Popup
35. Blog
36. Accordian
------------------------------------------*/

@import url(./custom_fonts.css);

/* temp */
.logo-temp {
	font-size: 26px;
	font-weight: bold;
}

body {
	background: #f9f9f9;
	font-family: OpenSansSemibold;
	font-size: 21px;
}
body.modal-open {
	overflow: overlay;
	padding: 0 !important;
}
/*/////Logo//////////*/
.logo {
	float: left;
	width: 170px;
	padding: 6px 0;
	margin-right: 10px;
}
.pages .navbar .logo {
	padding: 10px 0;
}
.logo img {
	width: 100%;
}
/*////////Login buttons/////*/
/*////////Mobile Menu/////////////////////*/
.navbar-toggle {
	margin: 0px;
}
.navbar-fixed-bottom .navbar-collapse,
.navbar-fixed-top .navbar-collapse {
	max-height: inherit;
}
.navbar-toggle .icon-bar {
	background-color: #fff;
	border-radius: 0;
	height: 4px;
	width: 25px;
}
.mobile-menu.add-job a {
	background: #12cd6a none repeat scroll 0 0 !important;
	border: medium none;
	border-radius: 23px;
	color: #ffffff !important;
	text-align: center;
}
/*******************************/
.logins {
	position: absolute;
	right: 60px;
	padding: 4px 0;
	cursor: pointer;
}
.logins span {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	border: 2px solid #12cd6a;
	border-radius: 26px;
	color: #232323;
	font-family: OpenSansBold;
	font-size: 13px;
	margin-right: 0;
	padding: 12px 30px;
	width: auto;
	float: right;
}
.logins span:hover {
	background: #12cd6a;
	color: #fff;
}

.logins span:hover {
	background: #12cd6a;
}
.logins .login {
	background: #12cd6a none repeat scroll 0 0;
	border-radius: 23px;
	color: #ffffff;
	float: right;
	line-height: 0;
	margin-left: 10px;
	padding: 15px 18px;
	text-align: center;
}
.logins .login:hover {
	background: #232323;
}
.logins .login i {
	font-size: 16px;
}

a {
	text-decoration: none;
}
h3 {
	font-family: OpenSansBold;
	font-size: 40px;
	margin: 0 0 4px;
	color: #333;
}
h4 {
	font-family: OpenSansRegular;
	font-size: 15px;
}
h4 span {
	font-family: OpenSansBold;
	width: 100%;
	float: left;
}
h5 {
	font-family: LatoBlack;
	font-size: 28px;
}
ul li {
	font-family: OpenSansRegular;
	font-size: 16px;
	padding-bottom: 12px;
}

/*///////Header Section///////*/
.top_header {
	position: absolute;
	top: 29px;
	left: 0;
	right: 0;
	z-index: 2;
}

/*/////////////////////////////*/

/*/////////Animations ///////*/
.navbar-fixed-top,
.filter-result:hover,
a,
.btn,
.related_jos,
input[type="submit"],
.posts-list .post-thumb img,
.label {
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
	transition-duration: 0.2s;
}
/*////////////////////////*/
.navbar-fixed-top {
	padding: 29px 0;
}
.fixed-header .navbar-fixed-top {
	background: #fff;
	box-shadow: 0 0 1px #d6d6d6;
	padding: 10px 0;
}

nav.navbar.home {
	margin: 0;
}
.header_banner {
	position: relative;
	z-index: 1;
}
/*///////////////////*/
.pages .navbar {
	background: #fff;
}
.pages.fixed-header .navbar {
	padding: 10px 0;
}
/*///////////////*/
.slider_items {
	background: url(../images/headerimage1.jpg);
	height: 668px;
	background-attachment: fixed;
	background-position: 0 83%;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}
.slider_items:before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	background: #333;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
}
#main-menu {
	min-height: initial;
	margin-bottom: 0;
}
/*//////////Navigation Change to Hover //////*/
ul.dropdown-menu {
	border: 1px solid #fff;
	border-radius: 14px;
}
#header .navbar-nav li.dropdown:hover ul.dropdown-menu {
	display: block;
	width: 230px;
}

/*///////////Navigatiosns///////////////*/
.nav a {
	color: #333;
	font-family: OpenSansBold;
	font-size: 15px;
	text-transform: uppercase;
}
.nav li {
	margin-right: 15px;
	padding-bottom: 0;
}
/* .nav > li:first-child {
	padding: 0;
	border: medium none;
}
.nav > li:nth-of-type(2) {
	padding: 0;
	border: medium none;
} */
.nav > li a {
	padding: 13px 19px;
}
ul.dropdown-menu li a {
	text-transform: none;
	color: #333 !important;
	padding: 13px 20px;
	margin: 0px 18px;
	border-bottom: 1px solid #e9e9e9;
}
.nav > li:hover a,
.nav > li > a:focus,
.nav > li > a:hover,
.nav li.active a {
	color: #12cd6a;
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
}
ul.tiny_scrolling li a {
	text-transform: none;
	color: #333;
	padding: 13px 4px;
	margin: 0px 18px;
	border-bottom: 1px solid #e9e9e9;
}
ul.dropdown-menu li a:hover {
	color: #12cd6a !important;
	text-decoration: none;
}
ul.dropdown-menu li:last-child a {
	border: none;
}
/*////////////Slogan Area/////////*/
.container.slogan {
	text-align: center;
	color: #fff;
	position: absolute;
	left: 0;
	right: 0;
	top: 290px;
	z-index: 1;
}
.slogan h1 {
	color: #333333;
	font-family: LatoBlack;
	font-size: 58px;
}
.slogan h3 {
	margin: 0;
	font-family: RalewayLight;
	font-size: 25px;
	color: #333;
	text-align: center;
}
.slogan span {
	color: #12cd6a;
}
.slogan a:hover {
	background: #fff;
	color: #333;
	border: 2px solid #ddd;
}
.slogan a {
	background: #fff;
	border: 2px solid #12cd6a;
	color: #333;
	display: inline-block;
	font-family: OpenSansSemibold;
	font-size: 15px;
	margin-top: 46px;
	padding: 10px 31px;
	text-decoration: none !important;
	border-radius: 44px;
}
.slogan a span {
	font-family: OpenSansBold;
}
/*////////////////Jobs Filters//////////*/
.jobs_filters .container {
	padding: 0;
}
.jobs_filters .filters_feilds,
.jobs_filters .form-group input {
	width: 100%;
	text-align: left;
	border: none;
	border-radius: 0;
	line-height: normal;
	font-family: OpenSansSemibold;
	font-size: 14px;
	padding: 20px 28px 20px 20px;
	color: #333;
	height: inherit;
	border-radius: 0;
	border: none;
	border-radius: 11px;
}
::-webkit-input-placeholder {
	color: #333 !important;
}
:-moz-placeholder {
	/* Firefox 18- */
	color: #333 !important;
}
::-moz-placeholder {
	/* Firefox 19+ */
	color: #333 !important;
}
:-ms-input-placeholder {
	color: #333 !important;
}
.jobs_filters button .glyphicon {
	float: right;
	color: #12cd6a;
}
.jobs_filters {
	background: #12cd6a;
	padding: 24px 0 9px;
	box-shadow: 0 -17px 15px -17px #099b4e inset;
}
.jobs_filters .dropdown-menu {
	width: 100%;
	padding: 11px 0;
	border-radius: 14px;
}
.jobs_filters .dropdown-menu li a {
	border: none;
}
.tiny_scrolling::-webkit-scrollbar-track,
.select2-results::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #d0d0d0;
}

.tiny_scrolling::-webkit-scrollbar,
.select2-results::-webkit-scrollbar {
	width: 6px;
	background-color: transparent;
}

.tiny_scrolling::-webkit-scrollbar-thumb,
.select2-results::-webkit-scrollbar-thumb {
	background-color: #ef4136;
}
.tiny_scrolling,
.select2-results {
	overflow: auto;
	margin-right: 15px;
	height: 186px;
	padding: 0;
}
.jobs_filters .tiny_scrolling li span {
	border-bottom: 1px solid #f4f4f4;
	display: block;
	font-family: OpenSansSemibold;
	font-size: 15px;
	padding: 8px;
	cursor: pointer;
}
.jobs_filters .tiny_scrolling li span:hover {
	color: #12cd6a;
}
.tiny_scrolling li {
	padding: 0;
	list-style: none;
}
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:hover,
.open > .dropdown-toggle.btn-default {
	background: #fff !important;
}
.jobs_filters .form-group {
	margin-bottom: 13px;
}
.filter_width.bgicon {
	position: relative;
}

.filter_width.bgicon .glyphicon {
	position: absolute;
	right: 40px;
	top: 22px;
	color: #12cd6a;
}
.filter_width.bgicon .fa {
	font-size: 18px;
}
.jobs_filters .form-group .customsubmit {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	border: 2px solid #ffffff;
	text-indent: -9999px;
	cursor: pointer;
}
.jobs_filters .form-group .customsubmit:hover {
	background: #333 none repeat scroll 0 0;
	border: 2px solid #333;
}

.jobs_filters .form-group .fa-search {
	color: #fff;
	right: 40px;
}
.jobs_filters .filter_width > span {
	color: #fff;
	display: flex;
	font-size: 13px;
	margin-bottom: 2px;
	margin-top: 8px;
	padding-left: 21px;
	font-family: raleway;
}
/*///////////Main Container /////////*/
.main-container-job {
	padding: 18px 0px;
}
.main-container {
	padding: 58px 0px;
}
.main-container-home {
	padding: 29px 0;
}
/*///////////Tabs Filters//////*/
.tab_filters {
	float: left;
	width: 100%;
}
.tab_filters ul.nav li {
	margin-right: 9px;
	margin-left: 0;
}
.tab_filters ul.nav li span {
	border: 1px solid #d0d0d0;
	font-family: LatoRegular;
	font-size: 13px;
	color: #333;
	border-radius: 9px;
	text-transform: none;
	padding: 13px 19px;
	cursor: pointer;
}
.tab_filters ul.nav li span:hover {
	background: #333 none repeat scroll 0 0;
	border: 1px solid #333;
	color: #ffffff;
}
.tab_filters ul.nav li:last-child {
	margin-right: 0;
}
.tab_filters ul.nav li.active span {
	font-family: OpenSansBold;
	color: #fff;
	border: 1px solid #12cd6a;
	background: #12cd6a;
}
.tab_filters h5,
.tab_filters h4 {
	margin: 0;
	float: left;
	padding: 19px 0;
}
.tab_filters h5 {
	padding-right: 44px;
	border-right: 1px solid #d0d0d0;
	margin-right: 40px;
}

/*/////////////////Filters Result /////////*/
.jobs-result {
	float: left;
	width: 100%;
	margin-top: 28px;
}
.filter-result:hover {
	border-bottom: 2px solid #12cd6a;
}
.filter-result {
	background: #fff;
	padding: 20px 0;
	float: left;
	width: 100%;
	margin-bottom: 15px;
	border-bottom: 2px solid #e4e4e4;
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
	transition-duration: 0.2s;
}
.filter-result .company-left-info {
	float: left;

	margin-right: 26px;
}
.filter-result .desig {
	float: left;
}
.filter-result .desig h3,
.filter-result .desig h4 {
	margin: 0;
}
.filter-result .desig h3 {
	font-size: 18px;
	margin: 9px 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 300px;
}
.filter-result .desig h3:hover {
	color: #3e48d2;
	text-decoration: none;
}

.filter-result .desig h4 {
	font-size: 13px;
	color: #909090;
}
.label {
	background: #12cd6a none repeat scroll 0 0;
	border-radius: 0;
	float: left;
	font-family: OpenSansBold;
	font-size: 11px;
	line-height: normal;

	padding: 5px 11px;
}
.filter-result .location {
	width: 100%;
	text-align: right;
}
.filter-result .location p {
	font-size: 12px;
	line-height: normal;
	color: #333;
}
.data-job {
	text-align: left;
}
.data-job {
	float: right;
}
.data-job h3 {
	float: left;
	font-size: 17px;
	line-height: 22px;
	margin-right: 28px;
}
.data-job h4 {
	float: left;
}
.job-type.job-parttime {
	background: #31ce8a;
}
.job-type.job-internship {
	background: #efc36e;
}
.job-type.job-fulltime {
	background: #31ce8a;
}
.label.job-partytime {
	background: #f71212 none repeat scroll 0 0;
	border-radius: 33px;
	text-transform: none;
}
#load_more {
	text-align: center;
	display: block;
	margin: 0 auto;
	color: #333;
	padding: 12px 47px;
	font-family: OpenSansBold;
	position: relative;
	text-transform: uppercase;
	margin: 55px auto 28px;
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	border: 2px solid #12cd6a;
	border-radius: 26px;
}
#load_more:hover {
	background: #12cd6a !important;
	color: #fff;
}
#load_more .glyphicon {
	position: absolute;
	right: 17px;
	top: 14px;
}
/*/////////////////////////Continer Blue Section//////////*/
.container-fluid.bluesection {
	position: relative;
}
.container-fluid.bluesection:after,
.container-fluid.bluesection:before {
	content: "";
	position: absolute;
	height: 100%;
	width: 50%;
	top: 0;
	display: block;
	z-index: -1;
}
.container-fluid.bluesection:after {
	background: url(../images/blusectionimage.png) no-repeat;
	right: 0;
	width: 35%;
}
.container-fluid.bluesection:before {
	background: url(../images/blue-lines.png);
	left: 0;
	width: 65%;
}
.bluesection h3 {
	color: #fff;
}
.bluesection p {
	color: #ffffff;
	font-family: latoregular;
	font-size: 17px;
	line-height: 25px;
	padding-top: 26px;
}
/*//////////////////////////Jobs Conter/////////*/
#counter li {
	float: left;
	list-style: none;
	color: #fff;
	font-family: OpenSansBold;
	font-size: 50px;
	padding: 0px 6%;
	line-height: 40px;
	text-align: center;
	position: relative;
	width: initial;
}
#counter li:after {
	content: "";
	position: absolute;
	right: 0;
	height: 80%;
	background: #27e580;
	width: 1px;
	top: 0;
}
#counter li:last-child:after {
	opacity: 0;
}
#counter li span {
	font-size: 17px;
	font-family: LatoRegular;
	float: left;
	width: 100%;
}
#counter {
	float: left;
	padding: 0;
	width: 100%;
}
#counter li:first-child,
.how_work #counter li:first-child {
	padding-left: 0;
}
.count .num {
	display: inline-block;
}
#counter li:last-child {
	border: none;
	padding-right: 0;
}
/*/////////////Price Tags //////*/

.price-tags {
	text-align: center;
	position: relative;
	padding-bottom: 0;
}
.price-tags p {
	font-family: RalewayLight;
	font-size: 21px;
}
/*/////////Pricing Table ////////////*/

.price-tags .priceing_tables {
	position: relative;
	display: inline-block;
}
.price-tags .priceing_tables:after {
	content: "";
	position: absolute;
	top: 5px;
	height: 100%;
	width: 390px;
	display: block;
	background: url(../images/price_girl.png) no-repeat bottom left;
	left: -360px;
}
.priceing_tables .header {
	border-bottom: 1px solid #f2f2f2;
	color: #333;
	font-family: OpenSansBold;
	font-size: 25px;
	padding: 48px 0;
	position: relative;
}

priceing_tables .startup {
	background: #fff;
	float: left;
	width: 100%;
}

.priceing_tables .startup {
	background: #fff;
}
.priceing_tables .price_table {
	background: #ffffff none repeat scroll 0 0;
	box-shadow: 0px 0px 46px 0px #f0f0f0;
	-webkit-box-shadow: 0px 0px 46px 0px #f0f0f0;
	-moz-box-shadow: 0px 0px 46px 0px #f0f0f0;
	padding: 0;
}
.priceing_tables .price {
	padding: 3px 0;
	font-family: OpenSansBold;
	font-size: 63px;
	float: left;
	width: 100%;
	color: #333;
	height: 121px;
}

.priceing_tables .price span {
	font-family: LatoRegular;
	float: left;
	width: 100%;
	font-size: 15px;
}

.priceing_tables ul {
	padding: 0;
	list-style: none;
	margin-top: 25px;
	float: left;
	width: 100%;
	min-height: 289px;
	margin-bottom: 0;
	border-bottom: 1px solid #f6f6f6;
}
.priceing_tables ul li {
	border-bottom: 1px solid #f2f2f2;
	color: #333;
	padding: 13px;
}
.priceing_tables .purchase-now {
	float: left;
	width: 100%;
	margin: 25px 0;
}
.priceing_tables .purchase-now a {
	border: 2px solid #12cd6a;
	border-radius: 77px;
	color: #12cd6a;
	display: inherit;
	font-family: OpenSansBold;
	font-size: 15px;
	margin: 0 77px;
	padding: 17px 0;
	text-align: center;
	text-decoration: none !important;
	text-transform: uppercase;
}
.priceing_tables .pro .purchase-now a {
	background: #12cd6a none repeat scroll 0 0;
	border: 1px solid #12cd6a;
	color: #ffffff;
}
.priceing_tables .purchase-now a:hover {
	box-shadow: 0 15px 37px #cdcdcd;
}

.border.pro {
	border: 5px solid #12cd6a;
}

.priceing_tables .pro .header span {
	background: #fa2328 none repeat scroll 0 0;
	border-radius: 34px;
	color: #ffffff;
	font-family: OpenSansBold;
	font-size: 12px;
	left: 0;
	margin: 0 auto;
	padding: 13px 0;
	position: absolute;
	right: 0;
	text-transform: uppercase;
	top: 230px;
	width: 118px;
}
.priceing_tables .price i {
	font-family: Raleway;
	font-size: 26px;
	font-style: normal;
	position: relative;
	top: -15px;
	left: -10px;
}
/*///////////////Postslists/////////*/
.main-container.posts-list {
	padding-top: 0;
}

.posts-list .post-thumb {
	position: relative;
	overflow: hidden;
}
.posts-list .post-thumb img {
	width: 100%;
	-moz-transition: all 0.8s;
	-webkit-transition: all 0.8s;
	transition: all 0.8s;
}
.posts-list .post-meta {
	padding: 0 30px 24px;
	background: #fff;
	float: left;
	width: 100%;
}
.post-date {
	position: absolute;
	bottom: 10px;
	background: none;
	left: 30px;
}
.post-date .date {
	float: left;
	width: 100%;
	color: #fff;
	text-align: center;
	line-height: 30px;
}
.postdate {
	background: #12cd6a;
	font-size: 18px;
	font-family: OpenSansSemibold;
}
.postmonth {
	background: #333;
	font-family: OpenSansBold;
	font-size: 14px;
}
.posts-list .post-meta h4 {
	font-family: OpenSansBold;
	margin: 23px 0;
	line-height: 20px;
}
.posts-list .post-meta p {
	color: #333;
	font-family: LatoRegular;
	font-size: 13px;
	line-height: 29px;
	margin-bottom: 19px;
}
.posts-list .post-meta .readmore {
	border: 1px solid #12cd6a;
	border-radius: 22px;
	color: #12cd6a;
	float: left;
	font-family: OpenSansBold;
	font-size: 11px;
	padding: 9px 19px;
	text-transform: uppercase;
}
.posts-list .post-meta .readmore:hover {
	background: #333 none repeat scroll 0 0;
	border: 1px solid #333;
	color: #ffffff;
}
.container.main-container > p {
	font-family: RalewayLight;
	text-align: center;
}
/*//////////Image Banner ///////*/
.white-heading {
	color: #fff;
	font-family: opensansbold;
	font-size: 39px;
	margin-bottom: 0;
	/* text-align: center; */
}
.price-heading {
	color: #fff;
	font-family: opensansbold;
	font-size: 39px;
	margin-bottom: 0;
	text-align: center;
}
#style-2 .white-heading span.call-us {
	font-size: 23px;
	float: none;
	position: relative;
	top: -8px;
	padding: 0 50px;
}
.blue-banner .main-container {
	padding: 30px 0;
}
.green-banner .main-container {
	padding: 30px 0;
}
.white-heading span {
	font-family: RalewayThin;
}
.btn-getstarted {
	font-family: OpenSansBold;

	color: #fff;
	border: none;
	border-radius: 0;
	text-transform: uppercase;
	width: 100%;
	padding: 14px 18px;
}
.btn-getstarted.bg-red {
	background: none;
	border: 2px solid #fff;
	border-radius: 44px;
}
.btn-getstarted.bg-red:hover {
	background: #333;
	color: #fff;
	border: 2px solid #333;
}
.btn-blog {
	border: 2px solid #12cd6a;
	border-radius: 44px;
	color: #333;
	padding: 12px;
}
.btn-blog:hover {
	background: #12cd6a;
	color: #fff;
	border: 2px solid #12cd6a;
}
/*///////////////////*/
#owl-demo .item {
	text-align: center;
	width: 63%;
	margin: 0 auto;
}
#testio .item {
	text-align: center;
}
.owl-item .item h5,
.owl-item .item span {
	color: #ffffff;
	font-family: LatoRegular;
	font-size: 22px;
	margin: 5px 0;
}
.owl-item .item span {
	color: #12cd6a;
	width: initial;
}
#testio .owl-item .item p {
	color: #ffffff;
	font-family: LatoRegular;
	font-size: 20px;
	line-height: 30px;
	margin-top: 28px;
	position: relative;
}
.container .owl-pagination .owl-page.active span {
	background: #12cd6a;
	border-color: #12cd6a;
}
.container .owl-pagination .owl-page span {
	border: 2px solid #fff;
	background: none;
	width: 15px;
	height: 15px;
}
.owl-item .item p:after,
.owl-item .item p:before {
	content: "";
}

.clients ul {
	margin: 0;
	list-style: none;
	text-align: center;
	padding: 0;
}
.clients ul li {
	padding: 0;
	width: 110px;
	padding: 9px;
	display: inline-block;
	min-width: 5em;
	margin: 0 0.5em;
}
.clients ul li img {
	opacity: 0.59;
	width: 86px;
	text-decoration: none;
	display: block;
	font-family: Arial;
	position: relative;
	color: black;
	padding: 0.5em 0;
}
.clients ul li img:hover {
	opacity: 1;
}
.container-fluid.clients {
	background: #fff;
	box-shadow: 0 0 0 1px #e4e4e4;
	-moz-box-shadow: 0 0 0 1px #e4e4e4;
	-webkit-box-shadow: 0 0 0 1px #e4e4e4;
}
.container-fluid.clients .main-container {
	padding: 32px 0;
}
/*///////Footer///////*/

/* .main-container .list-group a::before {
	content: "";
	font-family: "FontAwesome";
	font-size: 13px;
	left: 15px;
	position: absolute;
} */

.main-container-home .list-group a::before {
	content: "";
	font-family: "FontAwesome";
	font-size: 13px;
	left: 15px;
	position: absolute;
}
.footer h3 {
	font-family: OpenSansBold;
	font-size: 18px;
	margin-bottom: 26px;
}
.footer ul li {
	list-style: none;
	font-size: 15px;
	padding-bottom: 5px;
}
.footer a {
	color: #333;
}
.footer ul li.active a,
.footer a:hover {
	color: #12cd6a;
	padding-left: 6px;
}
.footer p {
	font-size: 15px;
	color: #333;
	font-family: LatoRegular;
}
.container-fluid.last-footer {
	border-top: 1px solid #e3e3e3;
}
.container-fluid.last-footer .main-container {
	padding: 35px 0;
}
.last-footer p {
	font-family: LatoRegular;
}
.footer.last-footer ul li {
	padding-bottom: 0;
	float: left;
	padding-right: 20px;
}
.footer.last-footer ul {
	padding: 0;
}
.footer.last-footer ul li:first-child {
	padding-left: 0;
}
/*////////////////////Inner Page/////////////*/
.blue-banner {
	background: #12cd6a;
}
.green-banner {
	background: #12cd6a;
	box-shadow: 0 -17px 15px -17px #099b4e inset;
}
.page-title,
.header_area {
	margin-top: 67px;
}
.page-title .white-heading {
	font-family: OpenSansRegular;
	text-align: left;
	font-size: 49px;
}
.page-title .image-heading {
	font-family: OpenSansRegular;
	font-size: 34px;
	text-align: left;
}
.image-heading {
	color: #333;
	font-family: opensansbold;
	font-size: 39px;
	margin-bottom: 0;
	text-align: center;
}
/*////Favouorite ////////*/
.favourite {
	color: #fff;
	text-align: right;
	font-family: LatoRegular;
	font-size: 15px;
	padding: 5px 0;
	float: right;
}
.favourite.short {
	padding: 14px 0;
}
.favourite span {
	float: left;
	width: 100%;
}
.favourite.hard {
	font-family: RalewayHeavy;
	text-transform: uppercase;
	font-size: 28px;
	padding: 8px 0;
	line-height: normal;
}
.favourite i {
	margin-left: 5px;
}
.btn-print.bg-blue {
	background: #12cd6a;
	/* border: 2px solid #12cd6a; */
	border-radius: 40px;
	color: #fff;
}
/* .btn-print.bg-blue:hover {
	background: #232323;
	border: 2px solid #fff;
	color: #fff;
} */
/*////////Job Admin/////////*/
.label {
	border-radius: 14px;
}
.user-data div {
	display: table-cell;
	vertical-align: middle;
}
.user-data .user-image {
	padding-right: 16px !important;
}
.user-data .user-tag span {
	float: left;
	width: 100%;
	color: #12cd6a;
	font-family: LatoRegular;
}
.user-data .user-tag {
	line-height: normal;
	font-family: OpenSansBold;
	font-size: 15px;
	width: 100%;
}

.dashboard {
	background: #ffffff none repeat scroll 0 0;
	border-bottom: 1px solid #eee;
}
.user-data {
	display: table;
}
.dashboard .label {
	margin-right: 0;
}
.user-data .user-tag label {
	font-weight: lighter;
	line-height: normal;
	margin: 0;
	padding: 6px 0 0;
	font-size: 12px;
	font-family: LatoRegular;
}

.user-data .user-tag label span {
	font-size: 18px;
	color: #333;
	font-family: OpenSansBold;
	margin-top: 8px;
}

.user-data .seprator {
	padding-right: 3%;
	margin-right: 3%;
	position: relative;
	float: left;
	width: initial;
}

.user-data .seprator:last-child {
	padding: 0;
	margin: 0;
}

.user-data .seprator:before {
	position: absolute;
	background: #eee;
	right: 0;
	height: 100%;
	width: 1px;
	content: "";
}

.user-data .seprator:last-child:before {
	opacity: 0 !important;
}
/*////////////////*/
.white-bg {
	background: #fff;
}
.post-image img {
	float: left;
	width: 100%;
}
.post-image {
	float: left;
	width: 100%;
}

.content {
	float: left;
	width: 100%;
	padding: 26px 0;
}
.content h3 {
	font-size: 31px;
}
.content p {
	font-family: OpenSansRegular;
	font-size: 14px;
	line-height: 26px;
	margin-top: 24px;
	text-align: justify;
}
.content h2 {
	font-family: opensansbold;
	margin: 33px 0;
	float: left;
	width: 100%;
	color: #333;
}
ul.skills li {
	list-style: none;
}
.content ul li {
	font-family: OpenSansRegular;
	color: #333;
	font-size: 14px;
	list-style: outside none none;
	padding-bottom: 10px;
	padding-left: 25px;
	position: relative;
}
.content ul li:before {
	content: "";
	position: absolute;
	width: 8px;
	height: 8px;
	background: #12cd6a;
	border-radius: 100%;
	left: 0;
	top: 7px;
}
ul.alphabet li:before {
	content: counter(listStyle, upper-alpha) ".";
	background: none;
	top: 0;
}

.content ul {
	float: left;
	width: 100%;
	padding: 0;
}
ul.simple li:before {
	opacity: 0;
}

ul.simple li {
	padding-left: 0;
}
/*///////////Sidebar//////////*/
.widget .label {
	background: #232323 none repeat scroll 0 0;
	width: 100%;
	margin: 23px auto 3px;
	border-radius: 40px;
	font-size: 14px;
	padding: 17px 21px;
}
.widget .label i {
	float: right;
	position: absolute;
	right: 42px;
}
.sidebar .widget {
	margin-bottom: 56px;
}
.widget ul {
	padding: 0;
	list-style: none;
}
.widget .label.apply-job {
	background: #ffffff none repeat scroll 0 0;
	border: 2px solid #12cd6a;
	border-radius: 77px;
	color: #333;
}

.label.apply-job:hover {
	box-shadow: 0 15px 37px #cdcdcd;
}

.widget ul li {
	display: inline-block;
	width: 100%;
	text-align: center;
	position: relative;
}
.widget ul.social li a {
	color: #2a2a2a;
	font-size: 13px;
	position: relative;
	float: left;
	padding-bottom: 20px;
	text-align: center;
	width: 100%;
}
.widget ul.social li {
	text-align: center;
}
.widget ul.social li i {
	margin-right: 10px;
	width: 10px;
}
.widget ul.social {
	width: 50%;
	margin: 0 auto;
	float: none;
}
.sidebar .widget {
	margin-bottom: 56px;
	width: 100%;
	float: left;
}
.subscribe form,
.subscribe > div {
	text-align: center;
	background: #f6f6f6;
	padding: 28px 32px;
	margin-bottom: 21px;
	border-radius: 31px;
}
.sidebar .subscribe h3 {
	font-size: 15px;
	font-family: OpenSansBold;
	color: #333;
	text-transform: none;
	margin-bottom: 21px;
	line-height: normal;
}
.subscribe button {
	width: 100%;
}
.subscribe input,
.subscribe button {
	width: 100%;
	text-align: center;
	font-family: OpenSansBold;
	font-size: 13px;
	text-transform: uppercase;
	padding: 16px 0;
	border: none;
	background: #12cd6a;
	color: #fff;
	border-radius: 0;
}
.subscribe .form-group {
	margin-bottom: 0;
}
.subscribe input {
	text-transform: none;
	margin-bottom: 10px;
	border: 1px solid #e3e3e3;
	background: #fff;
	color: #333;
}
.subscribe {
	text-align: center;
}
.subscribe a {
	color: #2a2a2a;
	font-family: LatoRegular;
	font-size: 13px;
}
.subscribe i {
	margin-right: 5px;
}
/*///////////Recommended Jobs///////////*/
.job-recom .main-container {
	border-top: 1px solid #f3f3f3;
	padding: 36px 0;
}
.job-recom .main-container h3,
.recom {
	margin-bottom: 48px;
	font-family: OpenSansRegular;
	font-size: 30px;
}
.related_jos {
	background: #fff;
	padding: 26px;
	float: left;
	width: 100%;
	border-radius: 19px;
	border-bottom: 1px solid #f3f3f3;
}
.job-recom .recom-job {
	margin: 10px;
	float: left;
}
#recommended-job .related_jos h4 {
	font-family: OpenSansBold;
	font-size: 15px;
	color: #333;
	margin: 0 0 20px;
}
#recommended-job .related_jos p {
	float: left;
	width: 100%;
	font-family: LatoRegular;
	color: #858585;
	font-size: 13px;
	line-height: normal;
	margin: 21px 0 22px;
}
.related_jos .label {
	margin-bottom: 21px;
}
#recommended-job .owl-item .item span {
	color: #fff;
	font-size: 10px;
	padding: 10px;
	margin: 0;
}
#recommended-job .related_jos span.salary {
	border-top: 1px solid #dddddd;
	color: #333;
	float: left;
	font-family: OpenSansBold;
	font-size: 16px;
	margin: 0;
	padding: 17px 0 0;
	width: 100%;
}
/* #recommended-job .related_jos p:after,
#recommended-job .related_jos p:before {
} */
.related_jos span.salary i {
	float: right;
	cursor: pointer;
}

.job-recom .recom-job:hover .related_jos i.fa-star-o {
	display: none;
}

.job-recom .recom-job:hover .related_jos i.fa-star {
	display: block;
}

.job-recom .recom-job .related_jos i.fa-star {
	display: none;
}
.call-us {
	color: #fff;
	padding: 10px 0;
	float: left;
}
.job-recom .recom-job:hover .related_jos {
	border-bottom: 1px solid #12cd6a;
	box-shadow: 0 1px 18px #cdcdcd inset;
}
.job-recom .recom-job:hover .related_jos i {
	color: #12cd6a;
}
/*//////////////Page title with bg image//////////////////*/
.title-image .page-title {
	background: rgba(0, 0, 0, 0) url("../images/bgimage.png") repeat scroll 0 0 /
		cover;
}
.section-jobcategories {
	background: #ffffff none repeat scroll 0 0;
	opacity: 0.97;
}

.title-image .section-title {
	position: relative;
}
.title-image .section-title:after {
	content: "";
	position: absolute;

	bottom: 0;
	width: 100%;
	height: 1px;
	opacity: 0.2;
}
.title-image .section-title .main-container {
	padding: 68px 0 67px;
}
.title-image .user-data .user-tag,
.title-image .user-data .user-tag span {
	color: #fff;
}
.title-image .user-data .seprator:before {
	background: #fff;
	opacity: 0.2;
}
.title-image .page-title .job-admin,
.title-image .page-title .favourite {
	color: #333;
}
.section-jobinfo .main-container {
	padding: 20px 0;
}
/*//////////Top Jobs Filters//////////////////*/
ul.top_filters {
	margin: 0;
	padding: 0;
	list-style: none;
}
ul.top_filters li {
	padding-bottom: 0;
	color: #fff;
	font-size: 20px;
	float: left;
	padding-right: 60px;
	position: relative;
}
.joblist .jobs-result {
	margin: 0;
}
ul.top_filters li input[type="checkbox"] {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	opacity: 0;
	z-index: 2;
}

ul.top_filters li label {
	font-family: OpenSansBold;
	font-size: 15px;
	margin-bottom: 0;
	padding-left: 30px;
	position: relative;
	text-transform: uppercase;
	color: #333;
}
ul.top_filters li label:after {
	content: "";
	background: #fff;
	width: 14px;
	height: 14px;
	left: 0;
	position: absolute;
	top: 3px;
	border: 1px solid #c1c1c1;
}

ul.top_filters li input[type="checkbox"]:checked + label:after {
	background: #232323;
	border: none;
}
/*////////////Job list style 2/////////*/
/* .joblist.style2 {
} */
.joblist.style2 .filter-result {
	width: 49%;
	margin-right: 2%;
	padding: 20px 0;
	margin-bottom: 22px;
}
.joblist.style2 .filter-result:nth-child(even) {
	margin-right: 0;
}
.list-style2 .desig h3 {
	font-family: OpenSansBold;
	margin: 0 0 9px;
}
.list-style2 h4 {
	color: #848484;
}
.list-style2 .desig {
	float: none;
	min-height: 80px;
}
.list-style2 .company-left-info {
	padding: 0;
	margin-right: 14px;
}
.job-footer {
	float: left;
	width: 100%;
	margin-top: 10px;
	padding-top: 10px;
	border-top: 1px solid #e5e5e5;
	position: relative;
}
.job-footer .label {
	position: absolute;
	top: -53px;
	right: 0;
	margin: 0;
	border-radius: 33px;
	font-size: 10px;
	padding: 11px;
	/* width: 85px; */
}
.job-footer ul {
	padding: 0;
	list-style: none;
	float: left;
	width: 100%;
	margin: 0;
	padding: 10px 0 0;
}

.job-footer ul li {
	float: left;
	padding-right: 7%;
	margin-right: 8%;
	position: relative;
	padding-bottom: 0;
	color: #333;
	font-size: 15px;
}

.job-footer ul li:last-child {
	padding-right: 0;
	margin-right: 0;
}

.job-footer ul li:after {
	content: "";
	position: absolute;
	right: 0;
	height: 100%;
	background: #e5e5e5;
	width: 1px;
	top: 0;
}

.job-footer ul li:last-child:after {
	opacity: 0;
}
/*/////////////Job list style 3////////////////*/
/* .style3 .jobs_filters {
} */
.main-container.list-style3 {
	padding: 33px 0;
}
.main-container.list-style3 {
	padding: 33px 0;
}
.list-style3 ul.top_filters {
	float: left;
	width: 100%;
	padding-bottom: 33px;
}
.list-style3 ul.top_filters li {
	color: #1a1a1a;
}
/*//////////Job slider /////////*/
#jobs-slider .item {
	position: relative;
}
#jobs-slider .owl-item .item img {
	width: 100%;
}
#jobs-slider .item .overlay {
	position: absolute;
	bottom: 0;
}
#jobs-slider .item .overlay h3 {
	color: #fff;
	display: inline-block;
	line-height: 19px;
	font-family: RalewayExtraBold;
}
#jobs-slider .item .overlay h3 span {
	float: left;
	width: 100%;
	color: #fff;
	font-family: LatoRegular;
	padding-left: 25px;
}
.list-style3 .jobs.list-style2 {
	margin-top: 30px;
}
.slider-botom {
	background: #1a1a1a;
	float: left;
	width: 100%;
}
#jobs-slider .slider-botom .label {
	font-size: 10px;
	color: #fff;
	border-radius: 0;
}
.slider-botom h3,
.slider-botom h4 {
	float: left;
	width: 100%;
	margin: 0 0 2px;
	font-size: 18px;
}
.slider-botom h3 {
	color: #fff;
	font-size: 22px;
	font-family: OpenSansBold;
}
#jobs-slider .slider-botom h4,
#jobs-slider .slider-botom h5 {
	color: #848484;
	font-family: LatoRegular;
}
#jobs-slider .slider-botom h5 {
	font-size: 12px;
}
#jobs-slider .owl-controls {
	position: absolute;
	right: 23px;
	bottom: 105px;
	margin: 0;
}
#jobs-slider .owl-controls .owl-prev,
#jobs-slider .owl-controls .owl-next {
	background: none;
	opacity: 1;
	padding: 2px;
	font-size: 22px;
	margin: 0 0 0 9px;
}
#jobs-slider .owl-controls div:hover {
	color: #12cd6a;
}
#jobs-slider .item .overlay,
#jobs-slider .item .slider-botom {
	width: 100%;
	padding: 0 34px;
}
.tag {
	background: #ffffff none repeat scroll 0 0;
	box-shadow: 0 0 46px 0 #f0f0f0;
}
/* .tag h3,
.tag ul {
} */
.tag h3 {
	border-bottom: 1px solid #e5e5e5;
	color: #1a1a1a;
	font-family: OpenSansSemibold;
	font-size: 18px;
	margin: 0 5px 3px;
	padding: 32px 32px 25px;
}
.tag ul {
	padding: 0 40px;
	list-style: none;
	margin-bottom: 0;
}
.tag ul li {
	line-height: normal;
	padding-bottom: 11px;
	padding-top: 5px;
	display: block;
}
.tag ul li a {
	color: #333;
	font-size: 15px;
	font-family: LatoRegular;
	display: block;
}
.tag ul li a:hover {
	color: #12cd6a;
}
/*////////////pricing Tabes ///////*/
.title-image .pricing .container {
	padding: 60px 0;
}
.page-title p {
	color: #fff;
	font-size: 21px;
	font-family: Raleway;
	padding-top: 14px;
}
.title-image .pricing {
	background: url(../images/pricing_table.png) no-repeat;
	background-size: cover;
}
.lighttext p {
	font-family: RalewayLight;
	font-size: 21px;
	margin-top: 24px;
}
.main-container.blocks {
	padding-top: 0;
}
.blocks .block {
	box-shadow: 0 0 46px 0 #f0f0f0;
	float: left;
	width: 100%;
	padding: 30px;
}
.blocks h3 {
	border-bottom: 1px solid #eeeeee;
	font-size: 18px;
	padding-bottom: 16px;
	position: relative;
}
.blocks p {
	color: #333333;
	font-family: LatoRegular;
	font-size: 14px;
	font-weight: normal;
	line-height: 24px;
}
.blocks a {
	border: 1px solid #12cd6a;
	border-radius: 55px;
	color: #12cd6a;
	float: left;
	font-family: OpenSansBold;
	font-size: 11px;
	margin-top: 30px;
	padding: 9px 19px;
	text-transform: uppercase;
}
.blocks h3 i {
	float: left;
	width: 100%;
	padding-bottom: 27px;
	color: #31ce8a;
}
/*//////////Page How its Works/////*/
.icons-heading span {
	float: left;
	color: #232323;
	font-size: 60px;
}

.icons-heading h3,
.icons-heading p {
	padding-left: 110px;
}

.icons-heading h3 {
	font-size: 32px;
	font-family: RalewayHeavy;
	text-transform: uppercase;
	margin-bottom: 19px;
}
.icons-heading p {
	font-family: LatoRegular;
	font-size: 19px;
	color: #585858;
}
.how_work:after,
.how_work:before {
	content: "";
	position: absolute;
	background: red;
	height: 100%;
	width: 100%;
	left: 0;
	z-index: -1;
}
/* .how_work:after {
} */
.how_work:before {
	display: block;
	width: 50%;
	background: url(../images/how_it_work.png) left no-repeat;
}
.how_work {
	position: relative;
}
.how_work .main-container {
	padding: 0;
}
.how_work .tiny_scrolling {
	height: 1032px;
	min-width: 250px;
	margin-right: 0;
}
.how_work ul {
	list-style: none;
	padding: 0;
	margin-bottom: 0;
}

.how_work ul li {
	background: #12cd6a;
	padding: 69px 50px 69px;
	color: #fff;
	float: left;
	width: 100%;
}
.how_work ul li.active {
	background: #333;
}
.how_work ul li h3 {
	color: #fff;
	font-family: RalewayHeavy;
	text-transform: uppercase;
	font-size: 32px;
}
.how_work ul li p {
	font-size: 17px;
	margin-bottom: 0;
	line-height: 25px;
}
.how_work .tiny_scrolling::-webkit-scrollbar-track {
	background-color: #5e86da;
}
.how_work .tiny_scrolling::-webkit-scrollbar {
	width: 9px;
}
.how_work #counter li {
	padding: 0 7%;
	background: none;
}
.how_work #counter li:after {
	background: #494949;
}
.how_work ul li ul li span {
	font-family: LatoRegular;
	font-size: 17px;
	display: block;
	width: 100%;
}

.how_work ul li ul li:after {
	content: "";
	position: absolute;
	height: 100%;
	width: 1px;
	top: 0;
	right: 0;
	background: #494949;
}

.how_work ul li.counter-row {
	background: #333;
}
.how_work ul li ul li:last-child {
	margin: 0;
	padding: 0;
}

.how_work ul li ul li:last-child:after {
	opacity: 0;
}
/*/////////Terms and conditions///////////*/
ul.alphabet li {
	counter-increment: listStyle;
}
/*////////Contact Us/////////////*/
.page-title h5 {
	font-size: 15px;
	color: #fff;
	font-family: LatoRegular;
	margin: 16px 0;
}
.form-group label {
	/* text-align: right; */
	float: left;
	width: 136px;
	margin-right: 22px;
	margin-bottom: 0;
	font-family: LatoRegular;
	font-size: 14px;
	font-weight: normal;
}
.form-group input,
.form-group select,
.form-group textarea {
	width: 100%;
	background: #fff;
	box-shadow: none;
	border: 1px solid #e1e1e1;
	padding: 9px 16px;
	font-size: 14px;
	color: #333;
	line-height: normal;
	font-family: LatoRegular;
	border-radius: 8px;
}
.contact_us .form-group input:focus,
.contact_us .form-group textarea:focus {
	border: 1px solid #12cd6a;
	box-shadow: none;
	color: #333;
}
.form-group.submit label {
	opacity: 0;
}
.contact_us .form-group input[type="submit"],
#register,
#signin {
	width: initial;
	font-family: OpenSansBold;
	border: none;
	font-size: 14px;
	background: #333;
	text-transform: uppercase;
	padding: 18px 46px;
	color: #fff;
	text-decoration: none;
	border-radius: 33px;
}
#form-style-2 .form-group {
	float: left;
	width: 100%;
}
.register:hover,
#signin:hover {
	border: 2px solid #ffffff;
}
.contact_us {
	border-bottom: 1px solid#e1e1e1;
}
.about_section h3 {
	font-size: 20px;
}
.about_section p {
	font-family: LatoRegular;
	font-size: 15px;
	line-height: 30px;
}
ul.contacts {
	padding: 0;
	list-style: none;
	margin-top: 40px;
	margin-bottom: 20px;
}

ul.contacts li i {
	margin-right: 10px;
}

ul.contacts li {
	padding-bottom: 10px;
}
ul.social {
	padding: 0;
	list-style: none;
	float: left;
	width: 100%;
}

ul.social li {
	float: left;
	padding-bottom: 0;
}

ul.social li a {
	color: #333;
	font-size: 20px;
	margin-right: 23px;
}
.about_section {
	padding-bottom: 80px;
	border-bottom: 1px solid #e4e4e4;
}
/*///Login and Register ////////////////*/
.deximJobs_tabs {
	/* box-shadow: 0 0 28px -3px #099b4e inset; */
	/* background: #f5eac1 none repeat scroll 0 0; */
	background: #ffffff none repeat scroll 0 0;
}

.deximJobs_tabs .main-container {
	padding: 80px 0;
}
/* .deximJobs_tabs .nav {
} */
.tab-content .form-group {
	float: left;
	width: 100%;
}
.deximJobs_tabs .tab-content {
	margin-top: 49px;
	float: left;
	width: 100%;
}
.deximJobs_tabs .nav li a {
	font-family: OpenSansBold;
	font-size: 45px;
	background: none !important;
	color: #007738;
	padding: 0;
	padding-right: 60px;
	line-height: normal;
}
.deximJobs_tabs .nav li a:hover {
	background: none !important;
	color: #fff;
}
.deximJobs_tabs .nav li.active a {
	color: #fff;
}
.deximJobs_tabs .contact_us {
	border: none;
}

.deximJobs_tabs .contact_us label {
	color: #302626;
	/* width: 130px; */
	/* margin-left: -51px;
	padding: 2% 0; */
}

.deximJobs_tabs .contact_us input {
	width: 66%;
	font-size: 13px;
	padding: 8px 17px;
	font-family: LatoRegular;
	border: 1px solid #12cd6a;
}
.contact_us .form-group .dropdown {
	width: 75%;
	float: left;
}
.deximJobs_tabs .dropdown button {
	border: none;
	text-align: left;
	border-radius: 12px;
	height: 42px;
	width: 100%;
}

.deximJobs_tabs .contact_us button span {
	float: right;
	font-size: 12px;
	position: absolute;
	right: 15px;
	top: 15px;
}
.deximJobs_tabs .dropdown .dropdown-menu {
	width: 100%;
}
.deximJobs_tabs .dropdown .dropdown-menu li a {
	padding: 10px 0;
	border: none;
	display: block;
}
.deximJobs_tabs p {
	color: #fff;
	font-size: 20px;
	margin-bottom: 36px;
}
.sidebar h3 {
	font-family: OpenSansSemibold;
	font-size: 24px;
	color: #fff;
	text-transform: uppercase;
	line-height: 33px;
	margin-bottom: 28px;
}
.deximJobs_tabs .sidebar {
	background: #0cad59;
	margin-top: -20px;
	border-radius: 13px;
}
.deximJobs_tabs .sidebar .widget {
	margin-bottom: 0;
	width: 100%;
	float: left;
	padding: 43px 30px;
}
.deximJobs_tabs .sidebar .widget li p {
	text-align: left;
	font-size: 15px;
	font-family: LatoRegular;
	margin-bottom: 0;
	line-height: 25px;
}
.deximJobs_tabs .sidebar .widget li {
	padding-bottom: 35px;
}
.deximJobs_tabs .sidebar .widget li i {
	margin-right: 18px;
}
.deximJobs_tabs .sidebar .widget li:last-child {
	padding-bottom: 0;
}
.register {
	background: #333 !important;
	font-size: 14px;
	font-family: LatoRegular;
}

.deximJobs_tabs .contact_us .lost_password {
	color: #333;
	font-size: 15px;
	padding: 10px 20px;
}
.form-group.submit .cbox {
	float: left;
	position: relative;
}
.form-group.submit .cbox input[type="checkbox"] {
	width: 100%;
	position: absolute;
	opacity: 0;
	height: 100%;
	z-index: 2;
}
.cbox span:after {
	content: "";
	background: #fff;
	position: absolute;
	left: 0;
	width: 14px;
	height: 14px;
	top: 4px;
	border: 1px solid #566991;
}
.form-group.submit .cbox span {
	font-size: 16px;
	color: #fff;
	padding-left: 23px;
}
.form-group.submit .cbox input[type="checkbox"]:checked + span:after {
	background: red;
}
#signin {
	background: #232323;
}
.deximJobs_tabs .sidebar .widget li .label.register {
	background: #333;
	width: initial;
	padding: 15px 40px;
}

.fa.fa-clock-o,
.fa.fa-child,
.fa.fa-check-circle-o {
	font-size: 26px;
}

/*//////////////Post A Job/////////////*/
.page-title .capital h5 {
	text-transform: uppercase;
}
ul.buttons {
	margin: 0;
	padding: 0;
	list-style: none;
}

ul.buttons li {
	float: left;
	padding-right: 15px;
	padding-bottom: 0;
}
ul.buttons li:first-child {
	padding-right: 55px;
}

ul.buttons li:last-child {
	padding-right: 0;
}
.post-job .main-container {
	padding: 18px 0;
}
.post-job .main-container label {
	font-family: OpenSansSemibold;
	font-size: 20px;
	margin: 0;
	padding: 8px 0;
}
ul.buttons li #signin,
ul.buttons li #register {
	float: left;

	height: 44px;
	line-height: 7px;
}
#form-style-2 label {
	width: 200px;
	padding: 17px 0;
}
.select2-container {
	width: 100%;
	border-radius: 0 !important;
	border: 1px solid #e2e2e2 !important;
	box-shadow: none !important;
}
ul.select2-choices {
	border-radius: 0 !important;
	background: #f9f9f9 !important;
	padding: 6px 23px 6px 15px !important;
	line-height: normal !important;
	box-shadow: none !important;
}
.select2-container a.select2-choice {
	display: inline-block;
	width: 100%;
	padding: 17px 15px;
	height: inherit;
	background: #fff;
}

.select2-container a.select2-choice .select2-arrow {
	opacity: 0;
}
.select2-container a.select2-choice .select2-chosen {
	color: #888e94;
	margin: 0;
	font-family: LatoRegular;
	font-size: 15px;
	font-weight: normal;
	float: left;
}
.select2-container a.select2-choice i {
	float: right;
	margin: 6px 0;
}
body .select2-search {
	display: none;
}
#form-style-2 .form-control ul:before {
	content: "Chose a category...";
	margin: 11px 0;
	float: left;
	font-family: LatoRegular;
	font-size: 16px;
	padding-right: 35px;
	color: #888e94;
}
.container .select2-container-multi ul.select2-choices li {
	background: #333333 none repeat scroll 0 0;
	border: medium none;
	border-radius: 33px;
	color: #ffffff;
	font-family: LatoRegular;
	font-size: 16px;
	margin: 5px 8px 5px 0;
	padding: 14px 30px 14px 17px;
}

.container .select2-container-multi ul.select2-choices li:last-child,
.container .select2-container-multi ul.select2-choices li.select2-search-field {
	display: none;
}
.container .select2-container-multi ul.select2-choices li a {
	right: 10px;
	position: absolute;
	left: initial;
	background: url(../images/removeitem.png) no-repeat;
	width: 8px;
	height: 8px;
	top: 17px;
	background-size: 100%;
}
.container .select2-container-multi ul.select2-choices li a:hover {
	background-position: initial !important;
}
body ul.select2-results {
	margin: 8px 8px 30px;
	padding-top: 0;
}
#form-style-2 .form-control:focus {
	box-shadow: none;
	border: none !important;
}
.select2-drop-active {
	border: 1px solid #e2e2e2 !important;
	border-top: 0p !important	 ;
}
body ul.select2-results li {
	padding: 11px 14px;
	margin-right: 22px;
	font-family: LatoRegular;
	font-size: 16px;
}
.container .select2-container-multi ul.select2-choices i {
	float: right;
	font-size: 20px;
	position: absolute;
	right: 23px;
	top: 35%;
}
#form-style-2 ::-webkit-input-placeholder {
	color: #888e94 !important;
}
#form-style-2 :-moz-placeholder {
	/* Firefox 18- */
	color: #888e94 !important;
}
#form-style-2 ::-moz-placeholder {
	/* Firefox 19+ */
	color: #888e94 !important;
}
#form-style-2 :-ms-input-placeholder {
	color: #888e94 !important;
}
#form-style-2 .main-container {
	padding-bottom: 0;
}
.user-information {
	border-bottom: 1px solid #e1e1e1;
	padding-bottom: 20px;
}
.user-data .main-container {
	padding-top: 28px;
}
.contact_us span {
	font-size: 14px;
	color: #333;
	font-weight: bold;
}
.contact_us span span {
	color: #12cd6a;
	font-weight: bold;
}
#form-style-2 label.default {
	padding: 5px 0;
}
#form-style-2 label.default span {
	font-weight: normal;
	font-size: 15px;
	color: #939393;
}
.file-type input {
	opacity: 0;
	position: relative;
	z-index: 2;
	cursor: pointer;
	width: initial !important;
}
.file-type .upload {
	position: absolute;
	top: 0;
	color: #fff;
	margin-left: 18px;
	/* padding: 7px 0; */
}
.file-type {
	position: relative;
}
.file-type .upload .filename {
	background: #12cd6a none repeat scroll 0 0;
	border-radius: 13px;
	color: #ffffff;
	cursor: pointer;
	display: inline-block;
	font-family: OpenSansSemibold;
	font-size: 13px;
	/* margin-right: 35px; */
	padding: 15px 17px;
	z-index: -1;
}
.file-type .upload .filename i {
	padding-right: 18px;
	color: #fff;
}
.file-type .upload i {
	color: #adadad;
	font-style: normal;
	font-size: 10px;
}
.form-group label.heading {
	font-size: 20px;
	font-family: OpenSansBold;
	padding: 0 !important;
}
.contact_us .form-group.half input {
	width: 265px;
}
.row.user-data {
	padding-bottom: 35px;
	border: 1px solid #e1e1e1;
}
#form-style-2 .file-type .default {
	padding: 4px 0;
}
#form-style-2 .file-type input[type="file"] {
	padding: 13px 0;
}
#form-style-2 .image_uplaod {
	display: none;
}
.image_uplaod img {
	width: 100%;
}

#form-style-2 .image_uplaod label {
	width: initial;
}
.image_uplaod a {
	float: left;
	width: 100%;
	text-align: left;
	margin-top: 17px;
	color: #232323;
}
.white-text p {
	color: #fff;
	font-family: LatoLight;
	font-size: 20px;
}
.center-small {
	width: initial;
	font-size: 15px;
	padding: 19px 60px;
	border: 1px solid transparent;
	margin-top: 31px;
	line-height: normal;
}
.job-page .main-container {
	padding: 50px 0;
}
.container .mce-tinymce {
	width: 100%;
	display: inline-block;
	background: #f9f9f9;
}
.row.company-details {
	margin-bottom: 30px;
}
.page-title .main-container {
	padding: 33px 0 32px;
}
.dashboard .main-container {
	padding: 40px 0 41px;
}
li.admin {
	color: #12cd6a;
}

li.admin span {
	color: #333;
	font-family: OpenSansBold;
	font-size: 15px;
}
.container .small-heading {
	font-size: 25px;
	margin: 18px 0;
	float: left;
	width: 100%;
}

ul.skills {
	margin: 0;
	padding: 0;
	list-style: none;
}

ul.skills li {
	float: left;
	background: #f6f6f6;
	padding: 13px 29px;
	line-height: normal;
	margin-right: 9px;
	font-size: 13px;
	color: #333;
}
ul.education li {
	margin: 0;
	padding: 22px 33px 22px;
	border-bottom: 1px solid #e3e3e3;
}
ul.education li:last-child {
	border-bottom: none;
}
ul.education .data-row:before {
	background: #e3e3e3;
	width: 16px;
	height: 16px;

	top: 5px;
	content: "";
	position: absolute;
	left: 0;
	border-radius: 100%;
}

ul.education li .data-row {
	padding-left: 48px;
	line-height: normal;
	position: relative;
}

ul.education .data-row:after {
	content: "";
	position: absolute;
	width: 13px;
	height: 1px;
	background: #e3e3e3;
	left: 24px;
	top: 13px;
}

ul.education {
	padding: 22px 0 0;
	background: #f6f6f6;
	margin: 18px 0;
}
ul.education h3 {
	margin: 20px 0;
	font-size: 17px;
	font-family: LatoRegular;
	font-weight: bold;
}

ul.education li p {
	font-size: 15px;
	font-weight: normal;
	margin-bottom: 0;
}
ul.education li:after,
ul.education li:before {
	opacity: 0;
}

/*///////////////////Checkout //////////*/
.checkout {
	padding: 21px;
}
.checkout .main-container {
	padding: 6px 0;
}
.checkout ul.buttons {
	text-align: center;
}

.checkout ul.buttons li {
	float: none;
	display: inline-block;
	padding: inherit;
}
.checkout ul.buttons li input,
.checkout ul.buttons li label {
	float: left;
}

.checkout ul.buttons li:first-child {
	padding-right: 27px;
}
.checkout ul.buttons li input {
	border: 1px solid #e2e2e2;
	padding: 10px;
	text-align: center;
	border-radius: 33px;
}
.order-total label.heading {
	opacity: 1;
	text-align: center !important;
	margin: 0;
	width: 100% !important;
	margin-bottom: 34px;
	display: inline-block !important;
}
.order-total .place-order {
	background: #333 none repeat scroll 0 0;
	border-radius: 38px;
	color: #fff;
	margin: 0;
	font-size: 14px;
	width: 100%;
	padding: 18px 0;
}
.order-total {
	margin-bottom: 50px;
}
.order-total .submit {
	float: right;
	width: 326px;
}
.order-items .table th:first-child,
.order-items .table td:first-child {
	width: 30%;
}
ul.order-table {
	padding: 0;
	list-style: none;
	width: 100%;
	float: left;
	border: 1px solid #e2e2e2;
	margin-bottom: 0px;
}

ul.order-table > li {
	padding-bottom: 0, 0, 0, 10px;
	background: #f9f9f9;
	position: relative;
	float: left;
	width: 100%;
	padding: 30px 26px;
}
ul.order-table li ul {
	padding: 0;
	list-style: none;
	display: inline-block;
	width: 100%;
}
ul.order-table li ul li {
	color: #444;
	float: left;
	padding-bottom: 0;
}
ul.order-table li strong {
	font-family: OpenSansBold;
	font-size: 15px;
}
.order-items ul.order-table li ul li {
	width: 29%;
	border: none;
	padding-bottom: 0;
}

.order-items ul.order-table li ul li:last-child {
	float: right;
	text-align: center;
	width: initial;
	padding-right: 5%;
}
ul.order-table > li:before {
	content: "";
	position: absolute;
	border-bottom: 1px solid #e1e1e1;
	width: 98%;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
}
.form-group.order-items .cbox input[type="checkbox"]:checked + span {
	color: #3668d0;
}
.form-group.order-items .cbox span:after {
	border-radius: 100%;
	background: #f9f9f9;
	border: 1px solid #e3e3e3;
}
.form-group.order-items .cbox input[type="checkbox"]:checked + span:after {
	background: #12cd6a;
}
ul.order-table > li.payment {
	padding: 30px 26px;
	background: #fff;
}
.cbox ul.order-table > li.payment .cbox span {
	color: #333;
}

ul.order-table > li.payment .cbox span {
	color: #333;
	font-weight: normal;
}
ul.order-table > li:last-child:before {
	opacity: 0;
}

#form-style-2 .payment label {
	height: 1px;
	padding: 0;
}
#form-style-2 .payment .order-table {
	border: none;
}
.confirm-payment .form-group .heading {
	width: 100%;
	text-align: left;
	margin: 0;
}
.confirm-payment .form-group label {
	margin-right: 72px;
}
.confirm-payment .order-items .order-table {
	width: 100%;
}
.user-info ul li ul li {
	padding-right: 27px;
}
.confirm-payment ul.order-table {
	width: 100%;
}
ul.order-table li span.mobile {
	font-family: LatoRegular;
	font-weight: normal;
	padding-left: 20px;
	display: none;
	line-height: normal;
	font-size: 16px;
	position: relative;
}
/*/////////Companies/////////////////*/
.companies ul.nav li a {
	color: #333;
	border-radius: 0;
	font-size: 20px;
	font-family: LatoRegular;
	padding: 10px 15px;
}
.companies ul.nav li a:hover {
	color: #333;
}
.companies .main-container {
	padding: 37px 0;
}
.companies .row {
	border-bottom: 1px solid#e3e3e3;
}
.companies ul.nav li.active a {
	color: #fff;
	background: #232323;
}
.companies ul.nav li {
	margin: 0;
	line-height: normal;
}
.companies ul.list {
	padding: 0;
	list-style: none;
}

.companies ul.list > li {
	float: left;
	padding: 20px 30px;
	width: 200px;
	margin-right: 35px;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	transition-duration: 0.3s;
}
.companies ul.list > li:hover {
	background: #f2f2f2 none repeat scroll 0 0;
}

.companies ul.list li ul li {
	float: left;
	width: 100%;
}

.companies ul.list li ul li {
	float: left;
	width: 100%;
}

.companies ul.list > li.active {
	background: #f6f6f6;
}

.companies ul.list > li span {
	float: left;
	width: 100%;
	font-family: OpenSansBold;
	font-size: 26px;
	color: #333;
	text-transform: uppercase;
}

.companies ul.list li ul {
	float: left;
	margin-top: 20px;
	padding: 0;
	list-style: none;
}

.companies ul.list li ul li a {
	color: #333;
}
.companies.fixed-tabs .companies-tags {
	position: fixed;
	left: 0;
	right: 0;
	top: 67px;
	background: #398ff5;
	z-index: 2;
}
.companies.fixed-tabs .companies-list {
	margin-top: 246px;
}
.companies.fixed-tabs .companies-tags a {
	color: #fff;
}
#add_new {
	width: 46px;
	height: 46px;
	color: #fff;
	background: #12cd6a;
	text-align: center;
	padding: 15px;
	float: left;
	font-size: 16px;
	border-radius: 17px;
}
.preview_cv a {
	margin: 0;
}
/*////////Model Popup//////*/
.container .modal-dialog {
	/* width: 455px; */
	margin-top: 103px;
}
.container .modal-dialog .modal-content {
	border-radius: 30px;
	float: left;
	width: 100%;
}
.modal-open .modal .modal-dialog {
	overflow: hidden;
}
.modal-header .close {
	position: absolute;
	right: 0;
	top: 0;
	opacity: 1;
	margin: 0;
	color: #f71212;
	/* width: 48px; */
	height: 48px;
	line-height: 0px;
	padding: 24px;
}
.container .modal-dialog .modal-header {
	position: relative;
}
.modal-dialog h3 {
	color: #333;
	text-transform: none;
	font-size: 24px;
	float: left;
	width: 100%;
	line-height: normal;
}
.modal-dialog h3 span {
	font-family: latoregular;
	font-size: 14px;
	float: left;
	width: 100%;
	color: #12cd6a;
	margin-top: 10px;
}
.modal-dialog label {
	float: left;
	width: 100%;
	text-align: left;
	margin-bottom: 1px;
}
.modal-dialog input,
.modal-dialog textarea {
	width: 100%;
	background: #f9f9f9;
	border: 1px solid #e2e2e2;
	padding: 11px 15px;
}
.modal-dialog .modal-body {
	padding: 27px;
}
.container .modal-dialog .upload {
	position: relative;
	float: left;
	width: 100%;
}
.container .modal-dialog .upload input {
	position: absolute;
	top: 0;
}
.container .modal-dialog .upload .filename {
	padding: 15px 16px;
	float: left;
}
.container .modal-dialog .upload i {
	padding: 14px 0;
	float: left;
}
.container .modal-dialog .upload .filename i {
	padding: 0;
	margin-right: 16px;
	position: static;
}
.modal-dialog .file-type {
	float: left;
}
.modal-dialog .modal-footer {
	float: left;
	width: 100%;
}
.modal-dialog .modal-footer span {
	text-align: center;
	width: 55%;
	float: none;
	margin: 0px auto;
	display: block;
	font-size: 13px;
}
.modal-dialog .modal-footer a {
	width: 100%;
	text-align: center;
	float: left;
}
.modal-dialog .modal-footer i {
	width: 100%;
	text-align: center;
	float: left;
	font-style: normal;
	margin: 10px 0;
}
.modal-dialog .modal-footer span.submit-resume {
	background: #333;
}

/*///////////////blog //*/

.page-blog .capital h5 {
	font-family: RalewayLight;
	font-size: 21px;
	margin: 13px 0;
}
/* form#search {
} */
form#search .form-group {
	margin-bottom: 0;
	position: relative;
}
form#search input {
	color: #333;
	font-size: 17px;
	font-family: LatoRegular;
	padding: 14px 27px;
	line-height: normal;
	border: none;
	width: 100%;
	font-weight: normal;
	border-radius: 33px;
}
form#search input[type="submit"] {
	background: #232323;
	color: #fff;
	width: 60px;
	opacity: 0;
	position: relative;
	z-index: 99;
}

form#search .form-group.submit {
	padding: 0;
}
form#search .form-group.submit .glyphicon,
form#search .form-group.submit input[type="submit"] {
	position: absolute;
	color: #fff;
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	border: 2px solid #ffffff;
	border-radius: 33px;
	padding: 13px;
	text-align: center;
	line-height: normal;
	font-size: 16px;
}
.posts-list .post {
	padding-bottom: 0;
	margin-bottom: 0;
}
.posts-list .post:last-child {
	border-bottom: none;
}
.page-blog .posts-list .post-thumb {
	margin-bottom: 30px;
}
.blog-posts .main-container {
	padding: 40px 0;
}
.page-blog .post-date {
	top: 25px;
	right: 35px;
	left: inherit;
	bottom: inherit;
}
.data-post {
	margin-bottom: 52px;
	padding-left: 31px;
	padding-right: 27px;
	padding-bottom: 7px;
}
.data-post h3 {
	font-size: 23px;
	font-family: OpenSansBold;
	line-height: normal;
}
.data-post p {
	font-family: OpenSansRegular;
	font-size: 15px;
	font-weight: 500;
	line-height: 22px;
	margin-bottom: 34px;
	margin-top: 18px;
}
.data-post .btn {
	width: 125px;
	font-size: 12px;
	margin-bottom: 20px;
}
#blog-sidebar .widget {
	margin-bottom: 30px;
	border-bottom: 1px solid #dedede;
}
#blog-sidebar .widget:last-child {
	border-bottom: none;
}
#blog-sidebar h3.widget-title {
	font-family: OpenSansBold;
	font-size: 27px;
	margin-bottom: 30px;
}
#blog-sidebar ul li {
	text-align: left;
	padding-left: 40px;
}
#blog-sidebar ul li a {
	text-decoration: none;
	color: #333;
}
#blog-sidebar ul li a:hover {
	color: #12cd6a;
}
#blog-sidebar ul li:before {
	content: "";
	width: 5px;
	height: 5px;
	background: #333;
	position: absolute;
	left: 23px;
	border-radius: 1000%;
	top: 8px;
}
#blog-sidebar .recent-post ul li:before {
	opacity: 0;
}

#blog-sidebar .recent-post ul li {
	padding-left: 0;
	padding-bottom: 16px;
	margin-bottom: 16px;
	border-bottom: 1px solid #dedede;
}
#blog-sidebar .recent-post ul li:last-child {
	margin-bottom: 0;
	border: none;
}
.comment-section {
	float: left;
	width: 100%;
	margin-top: 60px;
}
.comment-section h3 {
	font-family: OpenSansBold;
	font-size: 27px;
	margin-bottom: 37px;
}
.comment-section h3 span {
	color: #12cd6a;
}
.view-comments {
	float: left;
	width: 100%;
	background: #333;
	padding: 29px 27px;
}
.view-comments strong {
	font-family: OpenSansBold;
}
.comment-section {
	font-size: 15px;
	font-family: opensansregular;
	line-height: 25px;
}
.comment-section a {
	font-size: 15px;
}
.view-comments p {
	margin-bottom: 0;
	color: #fff;
}
.view-comments hr {
	margin: 24px 0;
}
span.login-ins {
	margin-left: 25px;
	margin-top: 35px;
	float: left;
}
.leave-reply {
	margin-top: 100px;
	float: left;
	width: 100%;
}
.leave-reply form {
	float: left;
	width: 100%;
	margin-top: 20px;
}
#post-comments {
	background: #12cd6a none repeat scroll 0 0;
	border: medium none;
	border-radius: 32px;
	color: #ffffff;
	font-size: 13px;
	font-weight: bold;
	text-transform: uppercase;
}
/*/////////Accordian /////////*/
#accordion .panel {
	border: none;
	border-radius: 0;
	box-shadow: none;
	margin: 0 0 1px;
}
#accordion .panel-heading {
	background: none;
	border: none;
	border-radius: 0;
	padding: 0;
}

#accordion .panel-heading a {
	display: block;
	background: #12cd6a;
	text-decoration: none;
	color: #fff;
	line-height: normal;
	padding: 18px 23px;
	font-size: 15px;
	font-family: OpenSansBold;
	text-transform: uppercase;
	position: relative;
}
#accordion .panel-heading a i {
	position: absolute;
	right: 25px;
	font-size: 18px;
	top: 18px;
	line-height: normal;
}
#accordion .panel-heading a[aria-expanded="true"] {
	background: #232323;
}
#accordion .panel-body {
	padding: 33px 22px 0px;
	font-size: 15px;
	line-height: 30px;
	font-family: LatoRegular;
}
.posts-list .post-thumb:hover img {
	opacity: 0.7;
	-moz-transform: scale(1.1);
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

#lost-password label {
	padding: 0;
	line-height: normal;
}
.slides iframe {
	width: 100%;
	height: 563px;
}
.parallax-window {
	min-height: 668px;
	background: transparent;
}

#loader-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
}
#loader {
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	width: 150px;
	height: 150px;
	margin: -75px 0 0 -75px;
	border-radius: 80%;
	border: 3px solid transparent;
	border-top-color: #333;
	-webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
	animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:before {
	content: "";
	position: absolute;
	top: 5px;
	left: 5px;
	right: 5px;
	bottom: 5px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: #fff;
	-webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
	animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:after {
	content: "";
	position: absolute;
	top: 15px;
	left: 15px;
	right: 15px;
	bottom: 15px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: #12cd6a;
	-webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
	animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}
#loadessr {
	background: #d9d9d9;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 999999;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
		-ms-transform: rotate(0deg); /* IE 9 */
		transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
	}
	100% {
		-webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
		-ms-transform: rotate(360deg); /* IE 9 */
		transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
	}
}
@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
		-ms-transform: rotate(0deg); /* IE 9 */
		transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
	}
	100% {
		-webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
		-ms-transform: rotate(360deg); /* IE 9 */
		transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
	}
}

/*/////////Loader /////////*/
