.min-experience,
.employerName,
.sort-data {
	padding-top: 5px;
	color: #414841;
	font-size: 12px;
	margin: 0;
}
.pt-14 {
	padding-top: 14px;
}
.employer-logo {
	width: 50px;
	border-radius: 5px;
	/* border: 1px solid #12cd6a; */
}
.job-post-details h3,
.btn-apply,
.go-back {
	cursor: pointer;
}
.log-in-page-footer p {
	font-size: 14px;
}

.modal-up {
	display: block !important;
	backdrop-filter: blur(5px);
	background: rgb(0 0 0 / 37%);
}
.submit-error {
	color: #f46a6a;
	font-size: 12px;
	padding-bottom: 10px;
}

/* bootstrap additional classes */
@keyframes spinner-grow {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	50% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}
.spinner-grow {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: text-bottom;
	background-color: currentColor;
	border-radius: 50%;
	opacity: 0;
	-webkit-animation: spinner-grow 0.75s linear infinite;
	animation: spinner-grow 0.75s linear infinite;
}
.spinner-grow-sm {
	width: 1rem;
	height: 1rem;
}
.invalid-feedback {
	width: 100%;
	margin-top: 4px;
	font-size: 10px;
	color: #f46a6a;
	/* margin-left: 126px; */
}

.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
	display: block;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
	border-color: #f46a6a;
	padding-right: calc(1.5em + 0.94rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.235rem) center;
	background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
	border-color: #f46a6a;
	-webkit-box-shadow: 0 0 0 0.15rem rgba(244, 106, 106, 0.25);
	box-shadow: 0 0 0 0.15rem rgba(244, 106, 106, 0.25);
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
	padding-right: calc(1.5em + 0.94rem);
	background-position: top calc(0.375em + 0.235rem) right
		calc(0.375em + 0.235rem);
}
.custom-select.is-invalid,
.was-validated .custom-select:invalid {
	border-color: #f46a6a;
	padding-right: calc((1em + 0.94rem) * 3 / 4 + 1.75rem);
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
			no-repeat right 0.75rem center/8px 10px,
		url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")
			#fff no-repeat center right 1.75rem / calc(0.75em + 0.47rem)
			calc(0.75em + 0.47rem);
}
.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
	border-color: #f46a6a;
	-webkit-box-shadow: 0 0 0 0.15rem rgba(244, 106, 106, 0.25);
	box-shadow: 0 0 0 0.15rem rgba(244, 106, 106, 0.25);
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
	color: #f46a6a;
}
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
	display: block;
}
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
	color: #f46a6a;
}
.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
	border-color: #f46a6a;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated
	.custom-control-input:invalid:checked
	~ .custom-control-label::before {
	border-color: #f89999;
	background-color: #f89999;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated
	.custom-control-input:invalid:focus
	~ .custom-control-label::before {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(244, 106, 106, 0.25);
	box-shadow: 0 0 0 0.15rem rgba(244, 106, 106, 0.25);
}
.custom-control-input.is-invalid:focus:not(:checked)
	~ .custom-control-label::before,
.was-validated
	.custom-control-input:invalid:focus:not(:checked)
	~ .custom-control-label::before {
	border-color: #f46a6a;
}
.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
	border-color: #f46a6a;
}
.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
	border-color: #f46a6a;
	-webkit-box-shadow: 0 0 0 0.15rem rgba(244, 106, 106, 0.25);
	box-shadow: 0 0 0 0.15rem rgba(244, 106, 106, 0.25);
}
/* .badge {
	display: inline-block;
	padding: 0.25em 0.4em;
	font-size: 75%;
	font-weight: 500;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */
.badge-soft-primary {
	color: #30d279 !important;
	background-color: rgb(147 230 85 / 18%) !important;
	margin-right: 4px;
}
/* bootstrap additional classes end*/
.sort-tab {
	flex-grow: 1;
	display: flex;

	font-family: "OpenSansSemibold";
}
.sort-data {
	padding-top: 5px !important;
	color: #414841 !important;
	font-size: 12px !important;
	/* margin: 0 !important; */
	font-family: OpenSansSemibold !important;
}
.logins .user-login {
	background: #12cd6a none repeat scroll 0 0;
	border-radius: 50%;
	color: #ffffff;
	float: left;
	line-height: 0;
	margin-left: 10px;
	padding: 15px 5px;
	text-align: center;
	width: 30px;
	height: 30px;
	font-size: 14px;
	margin-top: 5px;
}
.logins .user-name {
	border-radius: 50%;
	float: left;
	line-height: 0;
	padding: 15px 8px;
	font-size: 14px;
	margin-top: 6px;
}
.dropdown-menu.dropdown-menu-right.show {
	right: 35px;
	top: 72%;
}
ul.sorting-home li {
	border: 1px solid #d0d0d0;
	font-family: LatoRegular;
	font-size: 13px;
	color: #333;
	border-radius: 9px;
	text-transform: none;
	padding: 13px 19px;
	cursor: pointer;
	display: inline-block;
	margin: 10px;
}
ul.sorting-home li:hover {
	background: #333 none repeat scroll 0 0;
	border: 1px solid #333;
	color: #ffffff;
}
.edit-my-profile .MuiAutocomplete-tag {
	margin: 3px;
	max-width: calc(100% - 6px);
	background: #30d279 none repeat scroll 0 0;
	border: medium none;
	border-radius: 33px;
	color: #ffffff;
	font-family: LatoRegular;
}
.edit-my-profile span.MuiChip-label {
	font-size: 13px !important;
	font-weight: bold !important;
	color: #fff !important;
	padding: 7px;
}
.dwn-resume {
	color: #2a2a2a;
	font-family: LatoRegular;
	font-size: 16px;
}
.mt-12 {
	margin-top: 12px;
}
.about-section {
	background: #fff;
	padding: 50px 0;
}
.about-section-img {
	padding: 30px;
}
.about-section-h2 {
	margin-bottom: 25px;
}
.style2-check {
	list-style: none;
	margin-block-start: 0;
	padding-inline-start: 0;
	padding-top: 23px;
}
.style2-check li:before {
	/* display: inline-block; */
	margin-right: 10px;
	font-size: 16px;
}
.about-section-btn-area {
	padding-top: 30px;
}
.btn-submit {
	width: auto;

	font-family: OpenSansBold;

	text-transform: uppercase;
	padding: 16px 32px !important;
}
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
	outline: 0px !important;
	outline-offset: 0 !important;
}
.thin-font {
	font-family: RalewayThin;
}
.pt-80 {
	padding-top: 80px;
}
/* parallax start-------------------------- */

/* Override UGG site */
#main {
	width: 100%;
	padding: 0;
}
.content-asset p {
	margin: 0 auto;
}
.breadcrumb {
	display: none;
}

/* Helpers */
/**************************/
.margin-top-10 {
	padding-top: 10px;
}
.margin-bot-10 {
	padding-bottom: 10px;
}

/* Typography */
/**************************/
#parallax-world-of-ugg h1 {
	font-family: "Oswald", sans-serif;
	font-size: 24px;
	font-weight: 400;
	text-transform: uppercase;
	color: black;
	padding: 0;
	margin: 0;
}
#parallax-world-of-ugg h2 {
	font-family: "Oswald", sans-serif;
	font-size: 70px;
	letter-spacing: 10px;
	text-align: center;
	color: white;
	font-weight: 400;
	text-transform: uppercase;
	z-index: 10;
	opacity: 0.9;
}
#parallax-world-of-ugg h3 {
	font-family: "Oswald", sans-serif;
	font-size: 14px;
	line-height: 0;
	font-weight: 400;
	letter-spacing: 8px;
	text-transform: uppercase;
	color: black;
}
#parallax-world-of-ugg p {
	font-family: "Source Sans Pro", sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
}
.first-character {
	font-weight: 400;
	float: left;
	font-size: 84px;
	line-height: 64px;
	padding-top: 4px;
	padding-right: 8px;
	padding-left: 3px;
	font-family: "Source Sans Pro", sans-serif;
}

.sc {
	color: #3b8595;
}
.ny {
	color: #3d3c3a;
}
.atw {
	color: #c48660;
}

/* Section - Title */
/**************************/
#parallax-world-of-ugg .title {
	background: white;
	padding: 60px;
	margin: 0 auto;
	text-align: center;
}
#parallax-world-of-ugg .title h1 {
	font-size: 35px;
	letter-spacing: 8px;
}

/* Section - Block */
/**************************/
#parallax-world-of-ugg .block {
	background: white;
	padding: 60px;
	width: 820px;
	margin: 0 auto;
	text-align: justify;
}
#parallax-world-of-ugg .block-gray {
	background: #f2f2f2;
	padding: 60px;
}
#parallax-world-of-ugg .section-overlay-mask {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: black;
	opacity: 0.7;
}

/* Section - Parallax */
/**************************/
#parallax-world-of-ugg .parallax-one {
	padding-top: 200px;
	padding-bottom: 200px;
	overflow: hidden;
	position: relative;
	width: 100%;
	background-image: url(../images/home-parallax.jpg);
	background-attachment: fixed;
	background-size: cover;
	-moz-background-size: cover;
	-webkit-background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
}

/* Extras */
/**************************/
#parallax-world-of-ugg .line-break {
	border-bottom: 1px solid black;
	width: 150px;
	margin: 0 auto;
}

/* Media Queries */
/**************************/
@media screen and (max-width: 959px) and (min-width: 768px) {
	#parallax-world-of-ugg .block {
		padding: 40px;
		width: 620px;
	}
}
@media screen and (max-width: 767px) {
	#parallax-world-of-ugg .block {
		padding: 30px;
		width: 420px;
	}
	#parallax-world-of-ugg h2 {
		font-size: 30px;
	}
	#parallax-world-of-ugg .block {
		padding: 30px;
	}
	#parallax-world-of-ugg .parallax-one,
	#parallax-world-of-ugg .parallax-two,
	#parallax-world-of-ugg .parallax-three {
		padding-top: 100px;
		padding-bottom: 100px;
	}
}
@media screen and (max-width: 479px) {
	#parallax-world-of-ugg .block {
		padding: 30px 15px;
		width: 290px;
	}
}
/* parallax end-------------------------- */
h5.applied-job-location {
	font-size: 12px;
}
.about-company {
	font-size: 12px;
}
input:focus-visible {
	outline-offset: 0px;
}
input,
select,
textarea:focus-visible {
	outline: none;
}
.content.profile-details h2 {
	font-size: 17px;
	margin: 17px 0;
}
.content.profile-details p {
	font-family: OpenSansRegular;
	font-size: 14px;
	line-height: 26px;
	margin-top: 24px;
	text-align: justify;
	border-bottom: 0.5px solid #eae4e4;
	padding-bottom: 40px;
}
.quick-search {
	padding-bottom: 30px;
}
.row.sort-item {
	border-top: 1px solid #e0e0e0;
	padding: 33px 0;
}
.language {
	/* background: #12cd6a none repeat scroll 0 0; */
	border-radius: 23px;
	color: #ffffff;
	float: left;
	line-height: 0;
	margin-right: 10px;
	padding: 15px 18px;
	text-align: center;
}
.login-btn {
	width: 25% !important;
	margin: 0 auto;
}
input::-webkit-input-placeholder {
	color: red;
}
input:-moz-placeholder {
	color: red;
}
.mtop-15 {
	margin-top: 15px;
}
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
	position: absolute;
	margin-top: 4px\9;
	margin-left: -32px !important;
}
.ml-less-10 {
	margin-left: -10px;
}
.mt-40 {
	margin-top: 40px;
}
.fl-right {
	float: right;
}
.hrsaprator {
	margin-top: 20px;
	margin-bottom: 20px;
	border: 0;
	border-top: 0.5px solid #c5c5c5;
}
.contact-us-section-info {
	background: #fffbcc;
	padding: 6px 6px 9px 12px;
	margin: 0 15px 15px;
	clear: both;
}
.profile {
	padding-top: 10px;
}
.profile .radio-inline input[type="radio"] {
	margin-left: -45px !important;
}
.required-data{
	color: red!important;
	font-size: 14px;
}