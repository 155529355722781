@font-face {
	font-family: "LatoBold";
	src: url("../fonts/Lato\ Bold%EF%B9%96.eot#iefix");
	src: url("../fonts/Lato\ Bold%EF%B9%96.eot#iefix") format("embedded-opentype"),
		url("../fonts/Lato\ Bold.woff2") format("woff2"),
		url("../fonts/Lato\ Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-FEFF;
}
@font-face {
	font-family: "LatoHeavy";
	src: url("../fonts/Lato\ Heavy﹖.eot");
	src: url("../fonts/Lato\ Heavy%EF%B9%96.eot#iefix")
			format("embedded-opentype"),
		url("../fonts/Lato\ Heavy.woff2") format("woff2"),
		url("../fonts/Lato\ Heavy.woff") format("woff");
	font-weight: 800;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-FEFF;
}

@font-face {
	font-family: "LatoLight";
	src: url("../fonts/Lato\ Light.eot");
	src: url("../fonts/Lato\ Light.eot?#iefix") format("embedded-opentype"),
		url("../fonts/Lato\ Light.eot") format("woff");

	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-FEFF;
}
@font-face {
	font-family: "LatoRegular";
	src: url("../fonts/Lato\ Regular.woff2");
	src: url("../fonts/Lato\ Light.eot") format("embedded-opentype"),
		url("../fonts/Lato\ Regular.woff2") format("woff2"),
		url("../fonts/Lato-Regular.ttf") format("ttf");

	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-FEFF;
}
@font-face {
	font-family: "RalewayHeavy";
	src: url("../fonts/Raleway.woff2");
	src: url("../fonts/Raleway\ Light.woff2") format("embedded-opentype");

	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+000D-FB06;
}

@font-face {
	font-family: "LatoBlack";
	src: url("../fonts/Lato-Black.ttf");

	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-FEFF;
}
@font-face {
	font-family: "Raleway";
	src: url("../fonts/Raleway.woff2");
	src: url("../fonts/Raleway.woff2") format("embedded-opentype"),
		url("../fonts/Raleway.woff2") format("woff2");

	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-FB06;
}
@font-face {
	font-family: "RalewayLight";
	src: url("../fonts/Raleway\ Light.woff2");
	src: url("../fonts/Raleway\ Light.woff2") format("embedded-opentype"),
		url("../fonts/Raleway\ Light.woff2") format("woff2");

	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-FB06;
}
@font-face {
	font-family: "RalewayBold";
	src: url("../fonts/Raleway.woff2");
	src: url("../fonts/Raleway.woff2") format("embedded-opentype"),
		url("../fonts/Raleway.woff2") format("woff2");

	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-FB06;
}
@font-face {
	font-family: "RalewaySemiBoldy";
	src: url("../fonts/Raleway.woff2");
	src: url("../fonts/Raleway\ Light.woff2") format("embedded-opentype"),
		url("../fonts/Raleway.woff2") format("woff2");

	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-00FE;
}
@font-face {
	font-family: "OpenSansSemibold";
	src: url("../fonts/Open\ Sans\ Semibold.woff2");
	src: url("../fonts/Open\ Sans\ Semibold.woff2") format("embedded-opentype"),
		url("../fonts/Open\ Sans\ Semibold.woff2") format("woff2"),
		url("../fonts/Open\ Sans\ Semibold.woff2") format("woff");

	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-FFFD;
}
@font-face {
	font-family: "OpenSansRegular";
	src: url("../fonts/OpenSans-Regular.woff2");
	src: url("../fonts/OpenSans-Regular.woff2") format("embedded-opentype"),
		url("../fonts/OpenSans-Regular.woff2") format("woff2");

	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-FFFD;
}

@font-face {
	font-family: "OpenSansBold";
	src: url("../fonts/Open\ Sans\ Bold.woff2");
	src: url("../fonts/Open\ Sans\ Bold.woff2") format("embedded-opentype"),
		url("../fonts/Open\ Sans\ Bold.woff2") format("woff2");

	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-FFFD;
}

@font-face {
	font-family: "RalewayThin";
	src: url("../fonts/Raleway\ Thin.woff2");
	src: url("../fonts/Raleway\ Thin.woff2") format("embedded-opentype"),
		url("../fonts/Raleway\ Thin.woff2") format("woff2");

	font-weight: 100;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+000D-FB06;
}
@font-face {
	font-family: "RalewayExtraBold";
	src: url("../fonts/Raleway\ Light.woff2");
	src: url("../fonts/Raleway\ Light.woff2") format("embedded-opentype"),
		url("../fonts/Raleway\ Light.woff2") format("woff2");

	font-weight: 800;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-FB06;
}

/*  */
